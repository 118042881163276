'use client';
import { Modal } from '@/app/components/Modal/Modal';
import { useTranslation } from '@/app/i18n/client';

interface PopupProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onBackgroundClick?: () => void;
  onAfterClose?: () => void;
  style: {};
  heading?: React.ReactNode;
  body?: React.ReactNode;
  element?: React.ReactNode;
  icon?: React.ReactNode;
  closeText?: string;
  closeTextStyles?: string;
}

export const Popup = ({
  isOpen,
  onRequestClose,
  onBackgroundClick,
  onAfterClose,
  style,
  closeText,
  closeTextStyles = 'text-core-7 cursor-pointer font-light hover:underline',
  icon,
  heading,
  body,
  element,
}: PopupProps) => {
  const { t } = useTranslation();

  return (
    <div className="max-w-[30rem]">
      <Modal
        isOpen={isOpen}
        onRequestClose={onBackgroundClick || onRequestClose}
        style={style}
        onAfterClose={onAfterClose}
      >
        {icon}
        {heading}
        {body}
        {element}
        <button
          className={closeTextStyles}
          id="Modal Close"
          onClick={onRequestClose}
        >
          {closeText}
        </button>
      </Modal>
    </div>
  );
};
