'use client';
import { useFeatureFlags } from '@/app/hooks/useFeatureFlags';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import Confetti from 'react-confetti';
import { Banner } from '../Banner';

export interface InViewProps {
  children: React.ReactNode;
  linkedSections: string[];
}

export const InView = ({ children, linkedSections }: InViewProps) => {
  const [activeMenuItem, setActiveMenuItem] = useState<string>('overview');
  const previousY = useRef<number>();
  const intersectingGroup = useRef<IntersectionObserverEntry[]>([]);
  const scrollToEl = useRef<string>();
  const [isScrolling, setIsScrolling] = useState<boolean>(true);

  const onIntersection = useCallback((entries: any, opts: any) => {
    const intersectingElements: IntersectionObserverEntry[] = [];
    entries.forEach((entry: any) => {
      if (entry.isIntersecting) {
        intersectingElements.push(entry);
      }
    });

    let selectedElement;

    if (intersectingElements.length === 1) {
      selectedElement = intersectingElements[0];
      intersectingGroup.current = [];
    } else if (intersectingElements.length > 1) {
      // there are several intersecting elements, therefore get the lower element
      selectedElement = intersectingElements.reduce((prev, curr) => {
        return prev?.target &&
          (prev.target as HTMLElement)?.offsetTop &&
          (prev.target as HTMLElement)?.offsetTop >
            (curr.target as HTMLElement).offsetTop
          ? prev
          : curr;
      });
      intersectingGroup.current = intersectingElements;
    }

    if (selectedElement) {
      setActiveMenuItem(selectedElement.target.id);
      previousY.current = selectedElement?.boundingClientRect.y;
      scrollToEl.current = selectedElement.target.id;
    }
  }, []);

  const hasScrolledTop = () => {
    if (window.scrollY === 0) {
      setIsScrolling(false);
    } else if (!isScrolling) {
      setIsScrolling(true);
    }
  };

  useLayoutEffect(() => {
    let options = {
      root: null,
      rootMargin: '-20% 0px -70% 0px',
      threshold: 0.01,
    };
    const observer = new IntersectionObserver(onIntersection, options);

    linkedSections.forEach((linkedSection) => {
      const section = document.querySelector(`#${linkedSection}`);
      if (section && observer) {
        observer.observe(section);
      }
    });

    hasScrolledTop();
    window.addEventListener('scroll', hasScrolledTop);

    return () => {
      linkedSections.forEach((linkedSection) => {
        if (document.querySelector(`#${linkedSection}`) && observer)
          observer.unobserve(document.querySelector(`#${linkedSection}`)!);
      });
      window.removeEventListener('scroll', hasScrolledTop);
    };
  }, [onIntersection, linkedSections, hasScrolledTop]);

  const urlSearchParams = typeof window !== "undefined" ? new URLSearchParams(window.location.search).toString() : '';

  const featureFlags = useFeatureFlags(urlSearchParams);
  const confettiFeatureFlag = featureFlags['confetti'];

  return (
    <>
    {confettiFeatureFlag && <Confetti />}
      <div
        className={`z-20 sticky bg-opacity-80 backdrop-blur-md bg-core-1 top-0 ${
          isScrolling ? 'drop-shadow-menuDS' : ''
        }`}
      >
        <Banner activeAnchor={activeMenuItem} setAnchor={setActiveMenuItem} />
      </div>
      {children}
    </>
  );
};
