'use client';
import { useParams } from 'next/navigation';
import React, { useRef, useLayoutEffect } from 'react';

declare global {
  interface Window {
    Trustpilot: any;
  }
}

export const TrustPilot = ({
  className,
  height = '90px',
  width = '150px',
  theme = 'light',
}: {
  className?: string;
  height?: string;
  width?: string;
  theme?: string;
}) => {
  const params = useParams();

  const locale = params?.lang ?? 'en-GB';

  return (
    <div
      className={`trustpilot-widget ${
        className ? className : ' min-h-[90px] min-w-[150px]'
      }`}
      data-locale={locale}
      data-theme={theme}
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="5f3a881ba4989100019608f7"
      data-style-height={height}
      data-style-width={width}
    >
      <a
        href="https://www.trustpilot.com/review/example.com"
        target="_blank"
        rel="noopener"
        className="hidden"
      >
        Trustpilot
      </a>
    </div>
  );
};
