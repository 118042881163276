'use client';
import { Dropdown } from './Dropdown';
import { useState } from 'react';
import { useTranslation } from '@/app/i18n/client';
import { useAnalytics } from '@/app/components/Analytics';
import { usePartner } from '@/lib/partners/usePartner/client';

export const FAQs = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const { t, hasNonEmptyValue } = useTranslation();
  const analytics = useAnalytics();
  const partner = usePartner();

  const MAX_FAQ_ENTRIES = 5;

  const FAQElements = []

  for (let i = 1; i <= MAX_FAQ_ENTRIES; i++) {
    if (t(`faqs-answer-text-${i}`) !== 'OVERRIDE-DO-NOT-INCLUDE') {
      FAQElements.push({
        summary: t(`faqs-dropdown-header-${i}`),
        content: t(`faqs-answer-text-${i}`),
      });
    }
  };

  const toggleDropdown = (index: number) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  return (
    <div className="grid grid-cols-12  mt-20 bg-core-2 pt-12 pb-20 text-sm lmobile:text-base">
      <span className="laptop:col-span-6 col-span-12 max-w-text-column mx-auto  laptop:mr-8">
        <h2 className=" text-core-7 text-4 mt-6 tracking-[0.2rem]">
          {t('faqs-header')}
        </h2>
        <h2 className="text-header-lg-mobile lmobile:text-header-lg mt-3">
          {t('faqs-sub-header')}
        </h2>
        <p className="text-light font-light leading-7 max-w-text-column mt-5 mx-auto">
          {t('faqs-paragraph')}
        </p>
      </span>
      <span className="col-span-12 laptop:col-span-6 laptop:justify-center mt-8 laptop:mt-16 max-w-text-column mx-auto  w-full">
        {FAQElements.map((element, index) => (
          <div key={index}>
            <Dropdown
              index={index}
              openIndex={openIndex}
              summary={element.summary}
              content={element.content}
              toggleDropdown={() => {
                toggleDropdown(index);
                analytics && analytics.trackButtonClick(partner, element.summary, 'FAQDropdown');
              }}
            />
          </div>
        ))}
      </span>
    </div>
  );
};
