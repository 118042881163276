'use client';
import React, { useCallback, useEffect, useState } from 'react';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { usePartner } from '@/lib/partners/usePartner/client';
import { GlobeIcon } from '@/app/components/GlobeIcon';

export const LanguageSelector = () => {
  const partner = usePartner();
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const partnerSettings = getPartnerSettings(partner);
  let availableLanguages = partnerSettings?.languageLocale;
  if (availableLanguages && !Array.isArray(availableLanguages)) {
    availableLanguages = [availableLanguages];
  }

  const { languageLabels } = partnerSettings;

  const availableLanguagesAndLabels = availableLanguages?.map((lang) => {
    const langDetails = languageLabels?.find(
      (details) => details.value === lang,
    );

    if (langDetails) {
      return langDetails;
    }
  });

  const handleEsc = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      closeDisplay();
    }
  };

  const handleMouseClick = (event: any) => {
    if (!event?.target?.hasAttribute('data-language')) {
      closeDisplay();
      event.preventDefault();
    }
  };

  const openDisplay = () => {
    window.addEventListener('keydown', handleEsc);
    window.addEventListener('mouseup', handleMouseClick);
    setShowOptions(true);
  };

  const closeDisplay = () => {
    setShowOptions(false);
    window.removeEventListener('keydown', handleEsc);
    window.removeEventListener('mouseup', handleMouseClick);
  };

  let queryParams = '';
  if (typeof window !== 'undefined') {
    queryParams = new URLSearchParams(window.location.search).toString();
  }

  useEffect(() => {
    return () => {
      window.addEventListener('keydown', handleEsc);
      window.addEventListener('mouseup', handleMouseClick);
    };
  }, [handleEsc, handleMouseClick]);

  return (
    <>
      <button
        key={`button_expanded_${showOptions}`}
        aria-haspopup="true"
        aria-controls="languageOptions"
        aria-expanded={showOptions}
        id="languageMenuBtn"
        onClick={showOptions ? closeDisplay : openDisplay}
        data-language={true}
        className={`group flex flex-row p-2 z-10 ${showOptions ? 'open' : ''} `}
      >
        <span className="sr-only">Select your preferred language</span>
        <span data-language={true}>
          <GlobeIcon />
        </span>
        <span data-language={true}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            className="transition-transform duration-300 group-[.open]:rotate-180 translate-y-[2px] "
          >
            <path
              d="M8.00048 8.77873L11.3005 5.47873L12.2431 6.4214L8.00048 10.6641L3.75781 6.4214L4.70048 5.47873L8.00048 8.77873Z"
              className="fill-core-7"
            />
          </svg>
        </span>
      </button>
      {showOptions && (
        <div
          id="mask"
          className="absolute top-0 left-0 right-8 bg-core-1 w-full h-[80px] -z-10 drop-shadow-menuDS"
        ></div>
      )}
      {showOptions && (
        <>
          <ul
            id="languageOptions"
            role="menu"
            aria-labelledby="languageMenuBtn"
            className="absolute top-[80px] bg-core-1  drop-shadow-sm -z-20 left-0 right-0 h-[calc(100vh-80px)] pt-12 animate-slide-once "
          >
            {availableLanguagesAndLabels &&
              availableLanguagesAndLabels.map((language) => {
                return (
                  <li role="none" key={language?.label} className="text-center">
                    <a
                      role="menuitem"
                      href={`/${language?.value}${
                        queryParams ? `?${queryParams}` : ''
                      }`}
                      className="py-4 inline-block pl-2 pr-4 text-core-8 hover:text-core-6 text-2xl w-full max-w-[400px]"
                      data-language={true}
                    >
                      {language?.label}
                    </a>
                  </li>
                );
              })}
            <li>
              <button className="mt-10 underline" onClick={closeDisplay}>
                Close
              </button>
            </li>
          </ul>
        </>
      )}
    </>
  );
};
