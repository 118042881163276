'use client';

import { useTranslation } from '@/app/i18n/client';
import { Input } from '@/app/components/Input';
import { Button } from '@/app/components/Button';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { applicationStartingPoint as APP_START_URL } from '@/lib/pageroutes';
import persistenceService from '@/lib/utils/persistenceService';
import { STORAGE_KEY } from '@/app/contexts/global/actions';
import { ParsedText } from '@/app/components/ParsedText';
import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';
import { ReturnToJourneyCTA } from '../../ReturnToJourneyCTA';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { usePartner } from '@/lib/partners/usePartner/client';
import { setBrazeKeyValuePair, setBrazeUserEmail } from '../../../braze-sdk';

export const HeroEmailCapture = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const partner = usePartner();
  const partnerSettings: any = getPartnerSettings(partner);
  const allowReturnToJourney = partnerSettings.allowReturnToJourney;

  const host_url = getEnvVar('NEXT_PUBLIC_HOST_URL');

  if (!host_url) {
    return (
      <h1 className="text-error-1 font-bold text-xl">
        Error: HOST_URL is not set
      </h1>
    );
  }

  const errorAttribute = 'data-error';
  const revalidate = (e: Event) => {
    validateElement(e.target as HTMLInputElement, true);
  };

  const validateElement = (el: HTMLInputElement, inErrorState?: boolean) => {
    const elIsValid = el.checkValidity();

    if (!elIsValid) {
      el.setAttribute(errorAttribute, 'true');

      if (!inErrorState) {
        el.addEventListener('keyup', (e) => revalidate(e));
      }
    } else if (el.hasAttribute(errorAttribute)) {
      el.removeAttribute(errorAttribute);
      el.removeEventListener('keydown', revalidate);
    }

    return elIsValid;
  };

  const validateForm = (form: HTMLFormElement): boolean => {
    const formEls = form.elements;

    let valid = true;
    Array.from(formEls).forEach((el, i) => {
      const elIsValid = validateElement(el as HTMLInputElement);

      if (!elIsValid && valid) {
        valid = false;
      }
    });

    return valid;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const valid = validateForm(e.currentTarget);

    if (valid && typeof window !== 'undefined') {
      const formData = new FormData(e.currentTarget);
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      setBrazeUserEmail(formData.get('EmailCaptureInput')?.toString()!)
      setBrazeKeyValuePair('partner_code', partner)
      const storageData: any = {
        offersPayload: {
          application: {},
          applicants: [{ email_address: formData.get('EmailCaptureInput') }],
          marketing: {},
        },
        utm_campaign: params.utm_campaign || '',
      };

      // Application
      if (
        Object(params).hasOwnProperty('utm_term') &&
        params.utm_term &&
        !partnerSettings?.disallowMIDinURL
      ) {
        storageData.offersPayload.application.merchant_id = params.utm_term;
      }

      // Marketing
      const marketingFields = [
        'utm_campaign',
        'utm_content',
        'utm_source',
        'utm_medium',
        'utm_term',
      ];
      marketingFields.forEach((field) => {
        if (Object(params).hasOwnProperty(field) && params[field]) {
          const marketingKey = field.replace('utm_', 'marketing_');
          storageData.offersPayload.marketing[marketingKey] = params[field];
        }
      });

      persistenceService.set(STORAGE_KEY, storageData);
      setEmailSent(true);
      router.push(APP_START_URL);
    }
  };

  return (
    <>
      <form method="POST" autoComplete="off" noValidate onSubmit={handleSubmit}>
        <label htmlFor="HeroSectionInput" className="sr-only">
          {t('hero-email-placeholder')}
        </label>
        <Input
          type="email"
          placeholder={t('hero-email-placeholder')}
          id="HeroSectionInput"
          required
          errorMessage={t('hero-email-error-message')}
          name="EmailCaptureInput"
        />
        <Button
          type="submit"
          includeArrow={true}
          id={'HeroSectionButton-SubmitEmail'}
          mixpanelButtonText={t('hero-cta')}
          isLoading={emailSent}
        >
          {t('hero-cta')}
        </Button>
      </form>
      {allowReturnToJourney ? (
        <>
          <ReturnToJourneyCTA id="ReturnToJourney-HeroSection" />
          <p className="text-xs leading-5 text-center mt-3 mb-8 laptop:mt-7 text-core-7">
            <ParsedText
              htmlString={t('hero-disclaimer').replace(
                '{privacy-policy-url}',
                partnerSettings['privacy-policy-url'],
              )}
            />
          </p>
        </>
      ) : (
        <p className="text-xs leading-5 text-center mt-4 mb-8 laptop:mt-8 text-core-7">
          <ParsedText
            htmlString={t('hero-disclaimer').replace(
              '{privacy-policy-url}',
              partnerSettings['privacy-policy-url'],
            )}
          />
        </p>
      )}
    </>
  );
};
