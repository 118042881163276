'use client';

import React, { useState } from 'react';
import { CarouselProps } from './Carousel.types';
import styles from './Carousel.module.css';
import cx from 'classnames';
import { useSwipeable } from 'react-swipeable';

export const Carousel = ({ items, id }: CarouselProps) => {
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [direction, setDirection] = useState<string>('next');

  const nextSlide = () => {
    const nextSlideIndex = activeSlide + 1;
    if (activeSlide < items.length - 1) {
      setDirection('next');
      setActiveSlide(nextSlideIndex);
    }
  };

  const previousSlide = () => {
    const previousSlideIndex = activeSlide - 1;
    if (previousSlideIndex >= 0) {
      setDirection('previous');
      setActiveSlide(previousSlideIndex);
    }
  };

  const toggleSlide = () => {
    if (activeSlide === 0) {
      nextSlide();
    } else {
      previousSlide();
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: nextSlide,
    onSwipedRight: previousSlide,
    swipeDuration: 300,
    preventScrollOnSwipe: true,
    trackMouse: true,
    touchEventOptions: { passive: true },
  });

  return (
    <section className={styles.sectionBase} aria-labelledby={id}>
      <div className={styles.carouselViewport} {...handlers}>
        <div className="sr-only">A carousel showing {items.length} slides</div>
        <ul className={cx(styles.items)} data-activeslide={activeSlide}>
          {items.map((item, index) => (
            // Add conditional logic to apply a class to the active slide
            <li
              key={index}
              className={cx(styles.item, styles[direction], {
                [styles.activeSlide]: index === activeSlide,
              })}
            >
              {item}
            </li>
          ))}
        </ul>
        <div className={styles.circleRow}>
          {items.map((_, index) => (
            <button
              className={cx('cursor-pointer', styles.circle, {
                [styles.active]: index === activeSlide,
              })}
              key={`circle_${index}`}
              disabled={index === activeSlide}
              onClick={toggleSlide}
              aria-label={
                index !== activeSlide ? 'current slide' : 'next slide'
              }
            >
              <span className="sr-only">
                {index !== activeSlide && 'next slide'}
              </span>
            </button>
          ))}
        </div>
      </div>
    </section>
  );
};
