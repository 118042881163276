export type FundinCalculatorCountry =
  keyof typeof fundingCalculatorCountrySettings;

const fundingCalculatorCountrySettings = {
  GB: {
    currencyCode: 'GBP',
    minimumAmount: 1000,
    maximumAmount: 1_000_000,
    maximumSliderAmount: 500_000,
    exchangeRate: 1,
    amountRounding: 1_000,
  },
  IE: {
    currencyCode: 'EUR',
    minimumAmount: 1_200,
    maximumAmount: 100_000,
    maximumSliderAmount: 500_000,
    exchangeRate: 1.16,
    amountRounding: 1_000,
  },
  US: {
    currencyCode: 'USD',
    minimumAmount: 2_500,
    maximumAmount: 150_000,
    maximumSliderAmount: 75_000,
    exchangeRate: 1.22,
    amountRounding: 1_000,
  },
  CZ: {
    currencyCode: 'CZK',
    minimumAmount: 30_000,
    maximumAmount: 1_700_000,
    maximumSliderAmount: 850_000,
    exchangeRate: 29.42,
    amountRounding: 10_000,
  },
  SK: {
    currencyCode: 'EUR',
    minimumAmount: 1_200,
    maximumAmount: 60_000,
    maximumSliderAmount: 30_000,
    exchangeRate: 1.16,
    amountRounding: 1_000,
  },
  SE: {
    currencyCode: 'SEK',
    minimumAmount: 12_200,
    maximumAmount: 2_000_000,
    maximumSliderAmount: 1_000_000,
    exchangeRate: 11.81,
    amountRounding: 10_000,
  },
  FI: {
    currencyCode: 'EUR',
    minimumAmount: 1_200,
    maximumAmount: 200_000,
    maximumSliderAmount: 100_000,
    exchangeRate: 1.16,
    amountRounding: 1_000,
  },
  DK: {
    currencyCode: 'DKK',
    minimumAmount: 21_500,
    maximumAmount: 500_000,
    maximumSliderAmount: 250_000,
    exchangeRate: 8.66,
    amountRounding: 1_000,
  },
  DE: {
    currencyCode: 'EUR',
    minimumAmount: 1_200,
    maximumAmount: 200_000,
    maximumSliderAmount: 100_000,
    exchangeRate: 1.16,
    amountRounding: 1_000,
  },
  PL: {
    currencyCode: 'PLN',
    minimumAmount: 6_000,
    maximumAmount: 500_000,
    maximumSliderAmount: 250_000,
    exchangeRate: 5.28,
    amountRounding: 1_000,
  },
  NO: {
    currencyCode: 'NOK',
    minimumAmount: 12_200,
    maximumAmount: 2_000_000,
    maximumSliderAmount: 1_000_000,
    exchangeRate: 11.73,
    amountRounding: 10_000,
  },
  ES: {
    currencyCode: 'EUR',
    minimumAmount: 1_200,
    maximumAmount: 200_000,
    maximumSliderAmount: 100_000,
    exchangeRate: 1.16,
    amountRounding: 1_000,
  },
  IT: {
    currencyCode: 'EUR',
    minimumAmount: 1_200,
    maximumAmount: 200_000,
    maximumSliderAmount: 100_000,
    exchangeRate: 1.16,
    amountRounding: 1_000,
  },
  PT: {
    currencyCode: 'EUR',
    minimumAmount: 1_200,
    maximumAmount: 200_000,
    maximumSliderAmount: 100_000,
    exchangeRate: 1.16,
    amountRounding: 1_000,
  },
  AT: {
    currencyCode: 'EUR',
    minimumAmount: 1_200,
    maximumAmount: 200_000,
    maximumSliderAmount: 100_000,
    exchangeRate: 1.16,
    amountRounding: 1_000,
  },
  FR: {
    currencyCode: 'EUR',
    minimumAmount: 1_200,
    maximumAmount: 200_000,
    maximumSliderAmount: 100_000,
    exchangeRate: 1.16,
    amountRounding: 1_000,
  },
  CA: {
    currencyCode: 'CAD',
    minimumAmount: 2_500,
    maximumAmount: 150_000,
    maximumSliderAmount: 75_000,
    exchangeRate: 1.66,
    amountRounding: 1_000,
  },
  NL: {
    currencyCode: 'EUR',
    minimumAmount: 1_200,
    maximumAmount: 200_000,
    maximumSliderAmount: 100_000,
    exchangeRate: 1.16,
    amountRounding: 1_000,
  },
} as const;

// prettier-ignore
const ratiosMatrix = [
    [1.31,	1.32,	1.34],						
    [1.27,	1.29,	1.31,	1.32,	1.34],			
    [1.24,	1.26,	1.28,	1.3,	1.33,	1.35],			
    [1.21,	1.24,	1.26,	1.29,	1.32,	1.34],		
    [1.18,	1.2,	1.22,	1.25,	1.27,	1.3,	1.33],		
    [1.16,	1.18,	1.2,	1.23,	1.25,	1.28,	1.3,	1.33,	1.34],
    [1.15,	1.17,	1.19,	1.21,	1.23,	1.26,	1.28,	1.31,	1.32],
    [1.14,	1.16,	1.17,	1.19,	1.21,	1.24,	1.26,	1.28,	1.29],
    [1.13,	1.15,	1.16,	1.18,	1.2,	1.22,	1.25,	1.27,	1.28],
    [1.12,	1.14,	1.15,	1.17,	1.19,	1.21,	1.23,	1.25,	1.26],
    [1.12,	1.13,	1.14,	1.16,	1.18,	1.2,	1.21,	1.23,	1.24],
    [1.12,	1.13,	1.14,	1.16,	1.18,	1.2,	1.21,	1.23,	1.24],
    [1.11,	1.12,	1.13,	1.15,	1.16,	1.18,	1.19,	1.21,	1.22],
    [1.1,	  1.11,	1.12,	1.13,	1.15,	1.16,	1.18,	1.2,	1.21],
    [1.09,	1.1,	1.11,	1.13,	1.14,	1.15,	1.17,	1.18,	1.19],
    [1.09,	1.1,	1.11,	1.12,	1.13,	1.14,	1.15,	1.17,	1.18],
    [1.09,	1.1,	1.11,	1.12,	1.13,	1.14,	1.15,	1.16,	1.17]
] as const;

// prettier-ignore
const ratiosMatrixWeights = [
    1000,
    1500,
    2000,
    2500,
    4000,
    5000,
    6000,
    7000,
    8000,
    10000,
    12500,
    15000,
    20000,
    25000,
    30000,
    40000,
    60000,
    3000000
] as const;

export const useFundingCalculator = (country: FundinCalculatorCountry) => {
  const repaymentsAsPercentageOfYourMonthlyTakings = 0.2;
  const minMonthsSupported = 4;
  const countrySettings = fundingCalculatorCountrySettings[country];
  const { maximumAmount, exchangeRate, amountRounding } = countrySettings;

  const calculate = ({
    averageMonthlyTakings,
    timeInBuisnessInMonths,
  }: {
    averageMonthlyTakings: number;
    timeInBuisnessInMonths: number;
  }) => {
    const maxTerm = Math.min(timeInBuisnessInMonths + 0.5, 12);
    const maxRepayment =
      averageMonthlyTakings *
      repaymentsAsPercentageOfYourMonthlyTakings *
      maxTerm;

    const ratiosIndex = Math.floor(maxTerm) - minMonthsSupported;
    const ratios = ratiosMatrix.map((arr) => arr[ratiosIndex]);

    for (let i = ratios.length - 1; i >= 0; i--) {
      const ratio = ratios[i];
      const floor = ratiosMatrixWeights[i] * ratio * exchangeRate;
      const ceiling = ratiosMatrixWeights[i + 1] * ratio * exchangeRate;
      const result = Math.min(maxRepayment / ratio, maximumAmount);

      if (result > maximumAmount) continue;

      if (
        (i === 0 && maxRepayment < ceiling) ||
        (maxRepayment < ceiling && maxRepayment > floor) ||
        ratios[i - 1] === undefined
      ) {
        const roundedResult =
          Math.round(result / amountRounding) * amountRounding;

        return roundedResult;
      }
    }

    return null;
  };

  return {
    countrySettings,
    calculate,
  };
};
