'use client';

import { Button } from '@/app/components/Button';
import { Checkbox } from '@/app/components/Checkbox';
import { Input } from '@/app/components/Input';
import { ParsedText } from '@/app/components/ParsedText';
import { Tooltip } from '@/app/components/Tooltip';
import { useFeatureFlags } from '@/app/hooks/useFeatureFlags';
import {
  ApiCalls,
  EventTypes,
  UserEvents,
  useLogEvent,
} from '@/app/hooks/useLogEvent';
import { useTranslation } from '@/app/i18n/client';
import { applicationSubmittedUrl as FINAL_PAGE } from '@/lib/pageroutes';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { usePartner } from '@/lib/partners/usePartner/client';
import { generateUniqueId } from '@/lib/utils/generateUniqueId';
import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';
import { useRouter } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import persistenceService from '@/lib/utils/persistenceService';
import { STORAGE_KEY } from '@/app/contexts/global/actions';

export const LeadCaptureForm = ({
  inAppVersion,
}: {
  inAppVersion?: boolean;
}) => {
  const { t, hasNonEmptyValue } = useTranslation();
  const partner = usePartner();
  const router = useRouter();
  const { currency, midFormatRegex, id, phoneNumberRegex, contactPhoneNumber } =
    getPartnerSettings(partner);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [submissionError, setSubmissionError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const successMessage = useRef<HTMLDivElement>(null);
  let email = '';
  if (inAppVersion) {
    const storage = persistenceService.get(STORAGE_KEY);
    email = storage?.offersPayload?.applicants?.[0]?.email_address || '';
  }
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: email,
    telephone: '',
    mid: '',
    unknownMID: true,
    marketingConsent: false,
    contactConsent: true,
  });

  const urlSearchParams =
    typeof window !== 'undefined'
      ? new URLSearchParams(window.location.search).toString()
      : '';

  const featureFlags = useFeatureFlags(urlSearchParams);
  const midFeatureFlag = featureFlags['mid-field'];

  const host_url = getEnvVar('NEXT_PUBLIC_HOST_URL');
  const partnerURLHost = host_url?.replace('{partner}', partner);

  const logUserEvent = useLogEvent(EventTypes.USER_EVENT);
  const logApiCall = useLogEvent(EventTypes.API_CALL);
  const logError = useLogEvent(EventTypes.ERROR);

  useEffect(() => {
    if (submissionError) {
      throw new Error(`Error submitting lead capture form for ${partner}`);
    }
    if (hasSubmitted && successMessage) {
      if (inAppVersion) {
        router.push(FINAL_PAGE);
      } else {
        successMessage.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, [submissionError, partner, hasSubmitted]);

  if (!host_url) {
    return (
      <h1 className="text-error-1 font-bold text-xl">
        Error: HOST_URL is not set
      </h1>
    );
  }

  const errorAttribute = 'data-error';

  const revalidate = (e: Event) => {
    validateElement(e.target as HTMLInputElement, true);
  };

  const validateElement = (el: HTMLInputElement, inErrorState?: boolean) => {
    const elIsValid = el.checkValidity();

    if (!elIsValid) {
      el.setAttribute(errorAttribute, 'true');

      if (!inErrorState) {
        el.addEventListener('keyup', (e) => revalidate(e));
      }
    } else if (el.hasAttribute(errorAttribute)) {
      el.removeAttribute(errorAttribute);
      el.removeEventListener('keydown', revalidate);
    }

    return elIsValid;
  };

  const validateForm = (form: HTMLFormElement): boolean => {
    const formEls = form.elements;

    let valid = true;
    Array.from(formEls).forEach((el) => {
      const elIsValid = validateElement(el as HTMLInputElement);

      if (!elIsValid) {
        el.setAttribute(errorAttribute, 'true');
      } else if (el.hasAttribute(errorAttribute)) {
        el.removeAttribute(errorAttribute);
      }

      if (!elIsValid && valid) {
        valid = false;
      }
    });

    return valid;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value, type, checked } = e.target;

    if (id === 'unknownMID') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        unknownMID: checked,
        mid: checked ? '' : prevFormData.mid,
      }));
    } else {
      const finalValue = type === 'checkbox' ? checked : value;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [id]: finalValue,
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const valid = validateForm(e.currentTarget);

    const payload = {
      ...formData,
      partner: id,
      currency,
    };

    if (valid) {
      const traceId = generateUniqueId();
      const spanId = generateUniqueId();
      logApiCall(ApiCalls.LEAD_CAPTURE_FORM_SUBMISSION, traceId, spanId);
      setIsLoading(true);
      try {
        const response = await fetch(`${partnerURLHost}/api/${partner}/start`, {
          method: 'POST',
          headers: {
            'X-Datadog-Trace-Id': traceId,
            'X-Datadog-Parent-Id': spanId,
            'X-Datadog-Origin': 'local',
            'X-Datadog-Sampling-Priority': '1',
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          setHasSubmitted(true);
          logUserEvent(UserEvents.LEAD_CAPTURE_FORM_SUBMISSION);
        } else {
          setSubmissionError(true);
          setHasSubmitted(false);
        }
        setIsLoading(false);
      } catch (error: any) {
        logError(error, traceId, spanId);
        setSubmissionError(true);
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="mb-40">
      {!hasSubmitted ? (
        <form
          onSubmit={handleSubmit}
          action={`${host_url.replace('$partner', partner)}/api/start`}
          method="POST"
          autoComplete="off"
          noValidate
        >
          <div
            className={`mx-auto rounded-3xl w-full bg-core-1  ${
              !inAppVersion ? 'py-8 lmobile:p-8' : ''
            } text-center`}
          >
            {!inAppVersion && (
              <>
                <h2 className="text-header-lg  ">
                  {t('lead-capture-heading')}
                </h2>
                <div className="font-light text-core-7 leading-7 max-w-text-column mt-8 mb-10">
                  <ParsedText htmlString={t('lead-capture-text')} />
                </div>
              </>
            )}
            <div className="max-w-text-column text-left">
              <label
                htmlFor="firstName"
                className={`${inAppVersion ? 'font-normal' : 'font-light'}`}
              >
                {t('lead-capture-first-name-label')}
              </label>
              <Input
                type="text"
                id="firstName"
                required
                value={formData.firstName}
                onChange={handleChange}
                errorMessage={t('lead-capture-first-name-error-message')}
              />
              <label
                htmlFor="lastName"
                className={`${inAppVersion ? 'font-normal' : 'font-light'}`}
              >
                {t('lead-capture-last-name-label')}
              </label>
              <Input
                type="text"
                id="lastName"
                required
                value={formData.lastName}
                onChange={handleChange}
                errorMessage={t('lead-capture-last-name-error-message')}
              />
              <label
                htmlFor="email"
                className={`${inAppVersion ? 'font-normal' : 'font-light'}`}
              >
                {t('lead-capture-email-label')}
              </label>
              <Input
                type="text"
                id="email"
                required
                value={formData.email}
                onChange={handleChange}
                pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                errorMessage={t('lead-capture-email-error-message')}
                autoComplete="true"
              />
              <label
                htmlFor="telephone"
                className={`${inAppVersion ? 'font-normal' : 'font-light'}`}
              >
                {t('lead-capture-telephone-label')}
              </label>
              <Input
                type="text"
                id="telephone"
                required
                value={formData.telephone}
                onChange={handleChange}
                pattern={phoneNumberRegex}
                errorMessage={t('lead-capture-telephone-error-message')}
              />
              {midFeatureFlag && (
                <div className="inline-flex w-full justify-between">
                  <label
                    htmlFor="mid"
                    className={`${
                      inAppVersion ? 'font-normal' : 'font-light'
                    }  mr1`}
                  >
                    {t('lead-capture-mid-label')}
                  </label>
                  {hasNonEmptyValue('mid-tooltip-message') && (
                    <Tooltip
                      analyticEventPayload={{
                        partner: partner,
                        tooltipId:
                          'Quick Quote Marketing Form - reference field',
                        fieldName: 'reference',
                      }}
                      content={
                        <ParsedText
                          className="max-w-sm"
                          htmlString={t('mid-tooltip-message')}
                        />
                      }
                    >
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-primary float-right mt-[-0.5rem]"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.625 12.6426C2.625 7.12858 7.111 2.64258 12.625 2.64258C18.139 2.64258 22.625 7.12858 22.625 12.6426C22.625 18.1566 18.139 22.6426 12.625 22.6426C7.111 22.6426 2.625 18.1566 2.625 12.6426ZM12.625 20.6426C8.214 20.6426 4.625 17.0536 4.625 12.6426C4.625 8.23158 8.214 4.64258 12.625 4.64258C17.036 4.64258 20.625 8.23158 20.625 12.6426C20.625 17.0536 17.036 20.6426 12.625 20.6426ZM9.125 10.1426C9.125 8.21258 10.695 6.64258 12.625 6.64258C14.555 6.64258 16.125 8.21258 16.125 10.1426C16.125 11.7226 15.065 13.0456 13.625 13.4796V14.6426C13.625 15.1956 13.178 15.6426 12.625 15.6426C12.072 15.6426 11.625 15.1956 11.625 14.6426V12.6426C11.625 12.0896 12.072 11.6426 12.625 11.6426C13.452 11.6426 14.125 10.9696 14.125 10.1426C14.125 9.31558 13.452 8.64258 12.625 8.64258C11.798 8.64258 11.125 9.31558 11.125 10.1426C11.125 10.6956 10.678 11.1426 10.125 11.1426C9.572 11.1426 9.125 10.6956 9.125 10.1426ZM11.625 17.6426C11.625 17.0896 12.072 16.6426 12.625 16.6426C13.178 16.6426 13.625 17.0896 13.625 17.6426C13.625 18.1956 13.178 18.6426 12.625 18.6426C12.072 18.6426 11.625 18.1956 11.625 17.6426Z"
                          fill="currentColor"
                        />
                        <mask
                          id="mask0_14948_27090"
                          maskUnits="userSpaceOnUse"
                          x="2"
                          y="2"
                          width="21"
                          height="21"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.625 12.6426C2.625 7.12858 7.111 2.64258 12.625 2.64258C18.139 2.64258 22.625 7.12858 22.625 12.6426C22.625 18.1566 18.139 22.6426 12.625 22.6426C7.111 22.6426 2.625 18.1566 2.625 12.6426ZM12.625 20.6426C8.214 20.6426 4.625 17.0536 4.625 12.6426C4.625 8.23158 8.214 4.64258 12.625 4.64258C17.036 4.64258 20.625 8.23158 20.625 12.6426C20.625 17.0536 17.036 20.6426 12.625 20.6426ZM9.125 10.1426C9.125 8.21258 10.695 6.64258 12.625 6.64258C14.555 6.64258 16.125 8.21258 16.125 10.1426C16.125 11.7226 15.065 13.0456 13.625 13.4796V14.6426C13.625 15.1956 13.178 15.6426 12.625 15.6426C12.072 15.6426 11.625 15.1956 11.625 14.6426V12.6426C11.625 12.0896 12.072 11.6426 12.625 11.6426C13.452 11.6426 14.125 10.9696 14.125 10.1426C14.125 9.31558 13.452 8.64258 12.625 8.64258C11.798 8.64258 11.125 9.31558 11.125 10.1426C11.125 10.6956 10.678 11.1426 10.125 11.1426C9.572 11.1426 9.125 10.6956 9.125 10.1426ZM11.625 17.6426C11.625 17.0896 12.072 16.6426 12.625 16.6426C13.178 16.6426 13.625 17.0896 13.625 17.6426C13.625 18.1956 13.178 18.6426 12.625 18.6426C12.072 18.6426 11.625 18.1956 11.625 17.6426Z"
                            fill="white"
                          />
                        </mask>
                        <g mask="url(#mask0_14948_27090)"></g>
                      </svg>
                    </Tooltip>
                  )}
                </div>
              )}
              {midFeatureFlag && (
                <div>
                  <Input
                    type="text"
                    id="mid"
                    required
                    value={formData.mid}
                    onChange={handleChange}
                    pattern={midFormatRegex}
                    // disabled={formData.unknownMID}
                    errorMessage={t('lead-capture-mid-error-message')}
                  />
                </div>
              )}
              {/* <div className="-mt-6">
                <Checkbox
                  label={t('lead-capture-unknownMID-label')}
                  id="unknownMID"
                  checked={formData.unknownMID}
                  handleChange={handleChange}
                />
              </div> */}
              <fieldset>
                <legend
                  className={`${
                    inAppVersion ? 'font-normal' : 'font-light'
                  }   pt-3 pb-3 block text-sm lmobile:text-base`}
                >
                  {t('lead-capture-marketing-consent-text')}
                </legend>
                <Checkbox
                  label={t('lead-capture-marketing-consent-label')}
                  id="marketingConsent"
                  checked={formData.marketingConsent}
                  handleChange={handleChange}
                />
              </fieldset>
              <fieldset>
                <legend
                  className={`${
                    inAppVersion ? 'font-normal' : 'font-light'
                  }  pt-10 pb-3 block text-sm lmobile:text-base`}
                >
                  {t('lead-capture-contact-consent-text')}
                </legend>
                <Checkbox
                  label={t('lead-capture-contact-consent-label')}
                  id="contactConsent"
                  checked={formData.contactConsent}
                  handleChange={handleChange}
                />
              </fieldset>
              <div className="float-right w-full mt-10 ">
                <Button
                  type="submit"
                  includeArrow
                  id="leadCaptureSubmit"
                  mixpanelButtonText={t('lead-capture-submit-button-text')}
                  isLoading={isLoading}
                  loadingText={t('lead-capture-submit-loading-text')}
                  testID="LeadCaptureFormSubmitButton"
                >
                  {t('lead-capture-submit-button-text')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      ) : (
        !inAppVersion && (
          <div ref={successMessage}>
            <h2 className="header-lg-mobile laptop:text-header-lg mb-12 pt-24">
              {t('lead-capture-heading')}
            </h2>
            <h3 className="header-m-mobile laptop:text-header-m mb-8 ">
              {t('lead-capture-submitted-header')}
            </h3>
            <div className="font-light">
              <ParsedText
                htmlString={t('lead-capture-submitted-text').replace(
                  '{contactPhoneNumber}',
                  contactPhoneNumber!,
                )}
              />
            </div>
          </div>
        )
      )}
    </div>
  );
};
