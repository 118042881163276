import React from 'react';
import ReactSlider, { ReactSliderProps } from 'react-slider';
import cx from 'classnames';
import './Slider.css';

export interface SliderProps extends ReactSliderProps {
  color?: 'primary' | 'secondary';
  id?: string;
  step?: number;
}

export const Slider = (props: SliderProps) => {
  const {
    color = 'primary',
    className,
    orientation = 'horizontal',
    thumbClassName,
    trackClassName,
    thumbActiveClassName,
    id,
    disabled = false,
  } = props;

  return (
    <ReactSlider
      {...props}
      className={cx(
        'slider',
        `slider-${orientation}`,
        `slider-${color}`,
        className,
      )}
      thumbClassName={cx('slider-thumb', thumbClassName)}
      trackClassName={cx('slider-track', trackClassName)}
      thumbActiveClassName={cx('slider-thumb-active', thumbActiveClassName)}
      ariaLabelledby={id}
      step={props?.step ?? 1}
      disabled={disabled}
    />
  );
};
