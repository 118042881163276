import React from 'react';
import { formatValue, CurrencyInputProps } from 'react-currency-input-field';

export interface AmountProps extends React.HTMLProps<HTMLSpanElement> {
  children: string | number;
  intlConfig?: CurrencyInputProps['intlConfig'];
  decimals?: number;
}
export const Amount = (props: AmountProps) => {
  const { intlConfig, children, decimals, ...rest } = props;

  return (
    <span {...rest}>
      {formatValue({
        value: String(children),
        intlConfig,
        decimalScale: decimals ?? 0,
      })}
    </span>
  );
};
