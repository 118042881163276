'use client';

import './Chart.css';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { useTranslation } from '@/app/i18n/client';
import { usePartner } from '@/lib/partners/usePartner/client';
import { useParams } from 'next/navigation';

interface ChartProps {
  splitPercentage?: number | null;
  shortTitle?: string;
}

export const Chart = (params: ChartProps) => {
  const splitPercentage = params?.splitPercentage ?? null;
  const shortTitle = params?.shortTitle;
  const partner = usePartner();
  const partnerSettings: any = getPartnerSettings(partner);
  const { t } = useTranslation();
  const pathParams = useParams();
  const lang = pathParams.lang as string;
  // start the day text early for languages with wider text
  // this is to be refactored when the next language requires
  // a different transform
  let dayTextOffset = 5;
  let noPaymentOffset = 0;
  let noSalesOffset = -4;
  if (lang.startsWith('fi')) {
    dayTextOffset = -15;
    noPaymentOffset = 50;
  } else if (lang.startsWith('fr')) {
    dayTextOffset = -10;
    noPaymentOffset = -50;
    noSalesOffset = -60;
  } else if (lang.startsWith('pl')) {
    dayTextOffset = -15;
    noPaymentOffset = 160;
    noSalesOffset = 100;
  }

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1707.5 1303.7"
      xmlSpace="preserve"
      className="chartSVG"
      key="svgBarChart"
    >
      <g
        id="Day_1_-_Copy"
        transform={`translate(${dayTextOffset})`}
        className="day1copy"
      >
        <text
          transform="matrix(1 0 0 1 206.9663 1138.4464)"
          className="st0 st1"
        >
          {t('chart-day-label')}&nbsp;1
        </text>
      </g>
      <g
        id="Day_1_-_Amount"
        transform="translate(-4, 10)"
        className="day1Amountcopy"
      >
        <text
          transform="matrix(1 0 0 1 203.022 1224.8497)"
          className="st3 st0 st4"
        >
          {!partnerSettings?.currencySymbolAfterAmount &&
            `${partnerSettings.currencySymbol} `}
          <tspan className="st3 st0 st1">900</tspan>
          {partnerSettings?.currencySymbolAfterAmount &&
            ` ${partnerSettings.currencySymbol}`}
        </text>
      </g>
      <g id="Day_1_-_Split" className="day1split">
        <text
          transform="matrix(1 0 0 1 373.06 619.3751)"
          className="st3 st0 st4"
        >
          {!partnerSettings?.currencySymbolAfterAmount &&
            `${partnerSettings.currencySymbol} `}
          <tspan className="st3 st0 st1">
            {Math.floor(splitPercentage ? 900 * (splitPercentage / 100) : 135)}
          </tspan>
          {partnerSettings?.currencySymbolAfterAmount &&
            ` ${partnerSettings.currencySymbol}`}
        </text>
      </g>
      <g transform="translate(0, -4)">
        <g id="Day_1_-_Bar_Main">
          <rect
            x="228.4"
            y="649.6"
            className="st5 day1main"
            width="116.1"
            height="402"
          />
        </g>
        <g id="Day_1_-_Bar_Split" className="day1split">
          <g>
            <defs>
              <rect
                id="SVGID_1_"
                x="228.4"
                y="566.9"
                width="116.1"
                height="60.3"
              />
            </defs>
            <clipPath id="SVGID_00000079472634236502702840000004878397338343085708_">
              <use xlinkHref="#SVGID_1_" />
            </clipPath>
            <g clipPath="url(#SVGID_00000079472634236502702840000004878397338343085708_)">
              <g>
                <rect
                  x="-59"
                  y="507.2"
                  transform="matrix(0.7071 -0.7071 0.7071 0.7071 -316.9649 271.4616)"
                  className="st5"
                  width="456.3"
                  height="22.3"
                />

                <rect
                  x="-27.5"
                  y="538.7"
                  transform="matrix(0.7071 -0.7071 0.7071 0.7071 -330.011 302.9527)"
                  className="st5"
                  width="456.3"
                  height="22.3"
                />

                <rect
                  x="4"
                  y="570.2"
                  transform="matrix(0.7071 -0.7071 0.7071 0.7071 -343.0571 334.4437)"
                  className="st5"
                  width="456.3"
                  height="22.3"
                />

                <rect
                  x="35.5"
                  y="601.7"
                  transform="matrix(0.7071 -0.7071 0.7071 0.7071 -356.0962 365.9319)"
                  className="st5"
                  width="456.3"
                  height="22.3"
                />

                <rect
                  x="67"
                  y="633.2"
                  transform="matrix(0.7071 -0.7071 0.7071 0.7071 -369.1423 397.4229)"
                  className="st5"
                  width="456.3"
                  height="22.3"
                />

                <rect
                  x="98.5"
                  y="664.7"
                  transform="matrix(0.7071 -0.7071 0.7071 0.7071 -382.1784 428.9181)"
                  className="st5"
                  width="456.3"
                  height="22.3"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g
        id="Day_2_-_Copy"
        transform={`translate(${dayTextOffset})`}
        className="day2copy"
      >
        <text
          transform="matrix(1 0 0 1 567.2461 1138.4464)"
          className="st0 st1"
        >
          {t('chart-day-label')}&nbsp;2
        </text>
      </g>
      <g
        id="Day_2_-_Amount"
        transform="translate(-4, 10)"
        className="day2Amountcopy"
      >
        <text
          transform="matrix(1 0 0 1 567.3497 1224.8499)"
          className="st3 st0 st4"
        >
          {!partnerSettings?.currencySymbolAfterAmount &&
            `${partnerSettings.currencySymbol} `}
          <tspan className="st3 st0 st1">600</tspan>
          {partnerSettings?.currencySymbolAfterAmount &&
            ` ${partnerSettings.currencySymbol}`}
        </text>
      </g>
      <g id="Day_2_-_Split" className="day2split">
        <text
          transform="matrix(1 0 0 1 737.9031 751.2215)"
          className="st3 st0 st4"
        >
          {!partnerSettings?.currencySymbolAfterAmount &&
            `${partnerSettings.currencySymbol} `}
          <tspan className="st3 st0 st1">
            {Math.floor(splitPercentage ? 600 * (splitPercentage / 100) : 90)}
          </tspan>
          {partnerSettings?.currencySymbolAfterAmount &&
            ` ${partnerSettings.currencySymbol}`}
        </text>
      </g>
      <g id="Day_2_-_Bar_Main" className="day2main">
        <rect x="592.6" y="778" className="st5" width="116.1" height="268" />
      </g>
      <g id="Day_2_-_Bar_Split" className="day2split">
        <g>
          <defs>
            <rect
              id="SVGID_00000115482478553838282440000014115533318908699010_"
              x="592.6"
              y="715.4"
              width="116.1"
              height="40.2"
            />
          </defs>
          <clipPath id="SVGID_00000099657845238694981830000016203212941442527640_">
            <use xlinkHref="#SVGID_00000115482478553838282440000014115533318908699010_" />
          </clipPath>
          <g clipPath="url(#SVGID_00000099657845238694981830000016203212941442527640_)">
            <g>
              <rect
                x="346"
                y="625.6"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -282.0523 592.4883)"
                className="st5"
                width="456.3"
                height="22.3"
              />

              <rect
                x="377.5"
                y="657.1"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -295.1014 623.9865)"
                className="st5"
                width="456.3"
                height="22.3"
              />

              <rect
                x="409"
                y="688.6"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -308.1404 655.4745)"
                className="st5"
                width="456.3"
                height="22.3"
              />

              <rect
                x="440.5"
                y="720.1"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -321.1866 686.9656)"
                className="st5"
                width="456.3"
                height="22.3"
              />

              <rect
                x="472"
                y="751.5"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -334.2327 718.4566)"
                className="st5"
                width="456.3"
                height="22.3"
              />

              <rect
                x="503.5"
                y="783"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -347.2717 749.9448)"
                className="st5"
                width="456.3"
                height="22.3"
              />
            </g>
          </g>
        </g>
      </g>
      <g
        id="Day_3_-_Copy"
        transform={`translate(${dayTextOffset})`}
        className="day3copy"
      >
        <text transform="matrix(1 0 0 1 967.059 1138.4467)" className="st0 st1">
          {t('chart-day-label')}&nbsp;3
        </text>
      </g>
      <g
        id="Day_3_-_Amount"
        transform={`translate(${noSalesOffset}, 10)`}
        className="day3Amountcopy"
      >
        <text
          transform="matrix(1 0 0 1 925.0139 1224.8499)"
          className="st3 st0 st1"
        >
          {t('chart-no-sales')}
        </text>
      </g>
      <g
        id="Day_3_-_Null"
        className="day3split"
        transform={`translate(${noPaymentOffset})`}
      >
        <text
          transform="matrix(1 0 0 1 867.8212 982.2669)"
          className="st3 st0 st1"
        >
          {t('chart-no-payment')}
        </text>
      </g>
      <g
        id="Day_4_-_Copy"
        transform={`translate(${dayTextOffset})`}
        className="day4copy"
      >
        <text
          transform="matrix(1 0 0 1 1364.447 1138.4463)"
          className="st0 st1"
        >
          {t('chart-day-label')}&nbsp;4
        </text>
      </g>
      <g
        id="Day_4_-_Amount"
        transform="translate(-4, 10)"
        className="day4Amountcopy"
      >
        <text
          transform="matrix(1 0 0 1 1353.3712 1224.8499)"
          className="st3 st0 st4"
        >
          {!partnerSettings?.currencySymbolAfterAmount &&
            `${partnerSettings.currencySymbol} `}
          <tspan className="st3 st0 st1">1220</tspan>
          {partnerSettings?.currencySymbolAfterAmount &&
            ` ${partnerSettings.currencySymbol}`}
        </text>
      </g>
      <g id="Day_4_-_Split" className="day4split">
        <text
          transform="matrix(1 0 0 1 1536.7074 461.4205)"
          className="st3 st0 st4"
        >
          {!partnerSettings?.currencySymbolAfterAmount &&
            `${partnerSettings.currencySymbol} `}
          <tspan className="st3 st0 st1">
            {Math.floor(splitPercentage ? 1220 * (splitPercentage / 100) : 183)}
          </tspan>
          {partnerSettings?.currencySymbolAfterAmount &&
            ` ${partnerSettings.currencySymbol}`}
        </text>
      </g>
      <g id="Day_4_-_Main" className="day4main">
        <rect x="1395.6" y="501" className="st5" width="116.1" height="545" />
      </g>
      <g id="Day_4_-_Split-2" className="day4split">
        <g>
          <defs>
            <rect
              id="SVGID_00000099652795006380492270000013606029929888915082_"
              x="1395.6"
              y="397"
              width="116.1"
              height="81.8"
            />
          </defs>
          <clipPath id="SVGID_00000150104801463607832300000015667838608131010966_">
            <use xlinkHref="#SVGID_00000099652795006380492270000013606029929888915082_" />
          </clipPath>
          <g clipPath="url(#SVGID_00000150104801463607832300000015667838608131010966_)">
            <g>
              <rect
                x="1152.7"
                y="343.8"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 153.4631 1080.3461)"
                className="st5"
                width="456.3"
                height="22.3"
              />

              <rect
                x="1184.2"
                y="375.3"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 140.4169 1111.8373)"
                className="st5"
                width="456.3"
                height="22.3"
              />

              <rect
                x="1215.6"
                y="406.8"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 127.3708 1143.3282)"
                className="st5"
                width="456.3"
                height="22.3"
              />

              <rect
                x="1247.1"
                y="438.3"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 114.3247 1174.8135)"
                className="st5"
                width="456.3"
                height="22.3"
              />

              <rect
                x="1278.6"
                y="469.8"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 101.2815 1206.3115)"
                className="st5"
                width="456.3"
                height="22.3"
              />

              <rect
                x="1310.1"
                y="501.2"
                transform="matrix(0.7071 -0.7071 0.7071 0.7071 88.2424 1237.7997)"
                className="st5"
                width="456.3"
                height="22.3"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="key" transform="translate(-170, -30)" width="1500">
        <g id="Key_Icon">
          <g>
            <g>
              <defs>
                <circle
                  id="SVGID_00000007426859115076706770000011480579515575860888_"
                  cx="301.4"
                  cy="222.7"
                  r="46.8"
                />
              </defs>
              <clipPath id="SVGID_00000010271254780822764930000012972558638428159129_">
                <use xlinkHref="#SVGID_00000007426859115076706770000011480579515575860888_" />
              </clipPath>
              <g clipPath="url(#SVGID_00000010271254780822764930000012972558638428159129_)">
                <g>
                  <rect
                    x="-14.6"
                    y="111.3"
                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -24.0329 186.83)"
                    className="st5"
                    width="456.3"
                    height="22.3"
                  />

                  <rect
                    x="16.8"
                    y="142.8"
                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -37.0791 218.3211)"
                    className="st5"
                    width="456.3"
                    height="22.3"
                  />

                  <rect
                    x="48.3"
                    y="174.3"
                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -50.1181 249.8092)"
                    className="st5"
                    width="456.3"
                    height="22.3"
                  />

                  <rect
                    x="79.8"
                    y="205.8"
                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -63.1642 281.3003)"
                    className="st5"
                    width="456.3"
                    height="22.3"
                  />

                  <rect
                    x="111.3"
                    y="237.3"
                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -76.2103 312.7914)"
                    className="st5"
                    width="456.3"
                    height="22.3"
                  />

                  <rect
                    x="142.8"
                    y="268.7"
                    transform="matrix(0.7071 -0.7071 0.7071 0.7071 -89.2494 344.2794)"
                    className="st5"
                    width="456.3"
                    height="22.3"
                  />
                </g>
              </g>
            </g>
            <circle className="st10" cx="301" cy="223.2" r="46.3" />
          </g>
        </g>
        <g id="Key_Copy" transform="translate(0, -75)">
          <foreignObject
            width="1500"
            height="300"
            transform="matrix(1 0 0 1 387.5916 249.4684)"
          >
            <div className="st3 st0 st1 st6">
              {shortTitle ? t('chart-key-short') : t('chart-key-long')}
            </div>
          </foreignObject>
        </g>
      </g>
    </svg>
  );
};
