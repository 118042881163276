import { usePartner } from '@/lib/partners/usePartner/client';
import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';
import { useEffect, useState } from 'react';

interface FeatureFlags {
  [key: string]: boolean;
}

export const useFeatureFlags = (pageURLSearchParams?: string) => {
  const partner = usePartner();
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>({});
  const host_url = getEnvVar('NEXT_PUBLIC_HOST_URL');
  const partnerURLHost = host_url?.replace('{partner}', partner);

  let urlSearchParams = '';
  if (typeof window !== 'undefined') {
    urlSearchParams = pageURLSearchParams
      ? pageURLSearchParams
      : new URLSearchParams(window.location.search).toString();
  }

  useEffect(() => {
    let overrideQueryString: any;
    let ff: any;

    const parseQueryString = (ff: Record<string, boolean>) => {
      const params: Record<string, boolean> = {};

      const keyValuePairs = urlSearchParams?.split('&');

      keyValuePairs?.forEach((pair) => {
        const [key, value] = pair.split('=');

        if (ff.hasOwnProperty(key)) {
          params[key] = value === 'true';
        }
      });

      return params;
    };

    const fetchFeatureFlags = async (partner?: string) => {
      try {
        if (!(window as any).featureFlags) {
          const response = await fetch(
            `${partnerURLHost}/api/feature-flags?partner=${partner}`,
            {
              method: 'GET',
              headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                Pragma: 'no-cache',
                Expires: '0',
              },
            },
          );

          if (response.ok) {
            ff = await response.json();
          }
        } else {
          ff = (window as any)?.featureFlags;
        }

        overrideQueryString = parseQueryString(ff);

        setFeatureFlags((prevFeatureFlags) => ({
          ...prevFeatureFlags,
          ...ff,
          ...overrideQueryString,
        }));

        (window as any).featureFlags = {
          ...ff,
          ...overrideQueryString,
        };
      } catch (e) {
        console.error(e);
        return e;
      }
    };

    const fetchFlagsSequentially = async () => {
      await fetchFeatureFlags('all');
      await fetchFeatureFlags(partner);
    };

    fetchFlagsSequentially();
  }, [partner, partnerURLHost, urlSearchParams]);

  return featureFlags;
};
