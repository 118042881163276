'use client';

import { Amount, AmountProps } from '@/app/components/Amount';
import { Button } from '@/app/components/Button';
import { Slider } from '@/app/components/Slider';
import { useFundingCalculator } from '@/app/hooks';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from '@/app/i18n/client';
import { usePartner } from '@/lib/partners/usePartner/client';
import { useAnalytics } from '@/app/components/Analytics';

export const Calculator = () => {
  const partner = usePartner();
  const partnerSettings: any = getPartnerSettings(partner);
  const analytics = useAnalytics();
  const { t } = useTranslation();

  const { calculate, countrySettings } = useFundingCalculator(
    partnerSettings.country.toUpperCase(),
  );

  const intlConfig: AmountProps['intlConfig'] = {
    locale: partnerSettings.locale.replace('_', '-'),
    currency: countrySettings.currencyCode,
  };

  const [formState, setFormState] = useState({
    averageMonthlyRevenue:
      (countrySettings.maximumSliderAmount - countrySettings.minimumAmount) /
        2 +
      countrySettings.minimumAmount,
    businessOperatingFor: 6,
  });

  const fundingAmountQuoted =
    calculate({
      averageMonthlyTakings: formState['averageMonthlyRevenue'],
      timeInBuisnessInMonths: formState['businessOperatingFor'],
    }) || 0;

  const [previousAverageMonthlyRevenue, setPreviousAverageMonthlyRevenue] =
    useState(formState.averageMonthlyRevenue);
  const [previousMonthsInBusiness, setPreviousMonthsInBusiness] = useState(
    formState.businessOperatingFor,
  );
  const [previousFundingAmountQuoted, setPreviousFundingAmountQuoted] =
    useState(fundingAmountQuoted);

  // These two functions need to be split out because passing a setPreviousValue callback as an argument causes the value to be set incorrectly by the step amount of the slider

  const revenueDebouncedSliderChange = useDebouncedCallback(() => {
    analytics?.trackSliderRevenueChange(
      partner,
      'RevenueAmountChanged',
      previousAverageMonthlyRevenue,
      formState.averageMonthlyRevenue,
    );
    setPreviousAverageMonthlyRevenue(formState.averageMonthlyRevenue);
  }, 2000);

  const monthsDebouncedSliderChange = useDebouncedCallback(() => {
    analytics?.trackSliderMonthsInBusinessChange(
      partner,
      'MonthsInBusinessChanged',
      previousMonthsInBusiness,
      formState.businessOperatingFor,
    );
    setPreviousMonthsInBusiness(formState.businessOperatingFor);
  }, 2000);

  const fundingQuoteDebouncedChange = useDebouncedCallback(() => {
    analytics?.trackFundingQuoteChange(
      partner,
      'FundingQuoteAmountChanged',
      previousFundingAmountQuoted,
      fundingAmountQuoted,
    );
    setPreviousFundingAmountQuoted(fundingAmountQuoted);
  }, 2000);

  const handleSliderChange = (value: number, name: string) => {
    return setFormState((p) => ({
      ...p,
      [name]: value,
    }));
  };

  const businessOperatingForFormatted = t(
    'calculator-header-2-months-format',
  ).replace(
    '{months}',
    formState['businessOperatingFor'] < 12
      ? String(formState['businessOperatingFor'])
      : '12+',
  );

  return (
    <div className="p-8 flex flex-col h-full justify-between items-stretch">
      <div>
        <div className="text-sm text-gray-700">{t('calculator-header-1')}</div>

        <Amount className="block text-2xl" intlConfig={intlConfig}>
          {formState['averageMonthlyRevenue']}
        </Amount>
        <Slider
          {...{
            className: 'mt-4',
            id: 'average-monthly-revenue',
            name: 'averageMonthlyRevenue',
            value: formState['averageMonthlyRevenue'],
            onChange: (v) => {
              handleSliderChange(v, 'averageMonthlyRevenue');
              revenueDebouncedSliderChange();
              fundingQuoteDebouncedChange();
            },
            min: countrySettings.minimumAmount,
            max: countrySettings.maximumSliderAmount,
            step: 500,
            ariaLabel: t('calculator-header-1'),
          }}
        />
      </div>
      <div>
        <div className="text-sm text-gray-700">{t('calculator-header-2')}</div>

        <p className="text-2xl">{businessOperatingForFormatted}</p>

        <Slider
          {...{
            className: 'mt-4',
            id: 'business-operating-for',
            name: 'businessOperatingFor',
            value: formState['businessOperatingFor'],
            onChange: (v) => {
              handleSliderChange(v, 'businessOperatingFor');
              monthsDebouncedSliderChange();
              fundingQuoteDebouncedChange();
            },
            min: 4,
            max: 12,
            ariaLabel: t('calculator-header-2'),
          }}
        />
      </div>

      <div className="rounded-xl border-2 border-primary bg-primary/20 px-5 py-8">
        <p className="text-sm text-core-7">
          {t('calculator-eligible-amount-header')}
        </p>

        <Amount className="block text-2xl" intlConfig={intlConfig}>
          {fundingAmountQuoted}
        </Amount>
      </div>

      <div>
        <Button
          includeArrow={true}
          implementAsAnchor={true}
          href="#get-funded"
          id={'Calculator-GetMyQuote'}
          mixpanelButtonText={t('calculator-get-my-quote-button')}
        >
          {t('calculator-get-my-quote-button')}
        </Button>
      </div>
    </div>
  );
};
