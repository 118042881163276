export const GlobeIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM7.7105 17.667C6.72391 15.5743 6.15237 13.3102 6.0275 11H2.0625C2.46006 14.1442 4.67445 16.7581 7.7105 17.667ZM10.0013 17.752C8.87925 15.73 8.18225 13.439 8.03125 11H11.9713C11.8267 13.3695 11.1536 15.6766 10.0013 17.752ZM17.9371 11H13.9721C13.8472 13.3102 13.2756 15.5743 12.2891 17.667C15.3251 16.7581 17.5395 14.1442 17.9371 11ZM6.0275 9.00294H2.0625C2.46006 5.85878 4.67445 3.24488 7.7105 2.33594C6.72391 4.42862 6.15237 6.69272 6.0275 9.00294ZM11.9703 9.002C11.826 6.6326 11.1532 4.32553 10.0013 2.25C8.84893 4.32545 8.17581 6.63252 8.03125 9.002H8.03225H11.9703ZM13.9721 9.00294C13.8472 6.69272 13.2756 4.42862 12.2891 2.33594C15.3251 3.24488 17.5395 5.85878 17.9371 9.00294H13.9721Z"
        fill="currentColor"
      />
    </svg>
  );
};
