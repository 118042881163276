'use client';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { usePartner } from '@/lib/partners/usePartner/client';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export interface HowItWorksProps {}

export const HowItWorks = (props: HowItWorksProps) => {
  const playerFrame = useRef<any>();
  const { t } = useTranslation();
  const partner = usePartner();
  const partnerSettings: any = getPartnerSettings(partner);

  const playVideo = useCallback((entries: any, opts: any) => {
    if (
      entries[0].isIntersecting &&
      playerFrame.current &&
      window.hasOwnProperty('Vimeo')
    ) {
      const player = new window.Vimeo.Player(playerFrame.current);
      if (player.getPaused()) {
        player.play();
      }
    }
  }, []);

  useEffect(() => {
    let options = {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: 0.01,
    };
    const observer = new IntersectionObserver(playVideo, options);
    if (playerFrame.current) {
      observer.observe(playerFrame.current);
    }

    return () => {
      if (document.querySelector('vimeoIframe') && observer) {
        observer.unobserve(document.querySelector('vimeoIframe')!);
      }
    };
  }, [playVideo]);

  return (
    <div className="mt-16 laptop:mt-36" id="howItWorksVideo">
      <div className="bg-core-1 w-full max-w-[840px] mx-auto drop-shadow-ds1 rounded-3xl overflow-hidden">
        <div className="pt-[56.25%] relative">
          <script src="https://player.vimeo.com/api/player.js" defer></script>
          <iframe
            src={partnerSettings?.images['explainer-video-url']}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            title="Liberis_Explainer"
            className="absolute top-0 left-0 w-full h-full"
            loading="lazy"
            ref={playerFrame}
            id="vimeoIframe"
          ></iframe>
        </div>
      </div>
    </div>
  );
};
