import {
  ReactElement,
  useId,
  cloneElement,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import { usePopper, PopperProps } from 'react-popper';
import reactToText from 'react-to-text';
import cx from 'classnames';
import { useAnalytics } from '../Analytics';
import './Tooltip.scss';

export interface TooltipProps extends Pick<PopperProps<any>, 'placement'> {
  children: ReactElement;
  content: ReactNode;
  analyticEventPayload: {
    partner: string;
    tooltipId: string;
    fieldName: string;
  };
}

export const Tooltip = (props: TooltipProps) => {
  const { content, children, placement, analyticEventPayload } = props;

  const id = useId();
  const analytics = useAnalytics();

  const [isOpen, setIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<Element | null>(
    null,
  );
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowElement,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  useEffect(() => {
    const referenceEl = document.querySelector(`[aria-describedby="${id}"]`);
    const popperEl = document.getElementById(id);

    setReferenceElement(referenceEl);
    setPopperElement(popperEl);
  }, [id]);

  const showPopper = () => {
    analytics?.trackTooltipViewed(analyticEventPayload.partner, {
      text: reactToText(content),
      fieldName: analyticEventPayload.fieldName,
      tooltipId: analyticEventPayload.tooltipId,
    });

    setIsOpen(true);
  };

  const hidePopper = () => setIsOpen(false);

  return (
    <>
      {cloneElement(children, {
        onMouseEnter: showPopper,
        onMouseLeave: hidePopper,
        'aria-describedby': id,
      })}

      <div
        id={id}
        className={cx(
          {
            invisible: !isOpen,
          },
          'tooltip-root',
        )}
        onMouseEnter={showPopper}
        onMouseLeave={hidePopper}
        style={styles.popper}
        role="tooltip"
        {...attributes.popper}
      >
        {content}
        <div className="arrow" ref={setArrowElement} style={styles.arrow} />
      </div>
    </>
  );
};
